<template>
  <div class="row justify-content-md-center">
      <div class="row">
        <div class="col-md-12">
          <h2 class="principal header-title text-center">Gerenciamento de Notas Fiscais</h2>
        </div>
      </div>

      <div class="col-md-10">
        <h1 class="section-large-title">Nacional</h1>
        <div class="row justify-content-md-center wrap-dashboard">
          <!-- Gerar Nota Fiscal -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'InvoiceListBill'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('invoice.bill.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-archive"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('invoice.bill.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Consulta Notas Fiscais -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'InvoiceListIssued'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('invoice.issued.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-bar-chart-line"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('invoice.issued.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Preference Billing -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'InvoicePreferenceBilling'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('invoice.preferenceInvoice.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-dollar-sign"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('invoice.preferenceInvoice.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Type Moviments -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'InvoiceMovementTypes'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('invoice.typeMoviment.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ti-id-badge"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('invoice.typeMoviment.title') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

        </div>
        <!-- <div class="row justify-content-md-center wrap-dashboard">

          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'InvoiceListBill'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('invoice.receipt.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-archive"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('invoice.receipt.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->
      </div>

    </div>
</template>

<script>
export default {
  name: 'InvoiceHome',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('invoice.title') + ' - %s'
    }
  }
}
</script>

<style>

</style>
